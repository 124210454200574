import { styled } from "styled-components";
import { StyledProps } from "./common/types";

interface FilterButtonStyledProps {
  hideButton: boolean;
}

export const Wrapper = styled.main`
  position: fixed;
  backdrop-filter: blur(5px);
  top: 0;
  left: 0;
  z-index: 1000000000;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-flow: column;
  height: 100%;
  -webkit-animation: fadeInFromNone 0.3s ease-in-out;
  -moz-animation: fadeInFromNone 0.3s ease-in-out;
  -o-animation: fadeInFromNone 0.3s ease-in-out;
  animation: fadeInFromNone 0.3s ease-in-out;

  @-webkit-keyframes fadeInFromNone {
    0% {
      display: none;
      opacity: 0;
    }

    1% {
      display: block;
      opacity: 0;
    }

    100% {
      display: block;
      opacity: 1;
    }
  }

  @-moz-keyframes fadeInFromNone {
    0% {
      display: none;
      opacity: 0;
    }

    1% {
      display: block;
      opacity: 0;
    }

    100% {
      display: block;
      opacity: 1;
    }
  }

  @-o-keyframes fadeInFromNone {
    0% {
      display: none;
      opacity: 0;
    }

    1% {
      display: block;
      opacity: 0;
    }

    100% {
      display: block;
      opacity: 1;
    }
  }

  @keyframes fadeInFromNone {
    0% {
      display: none;
      opacity: 0;
    }

    1% {
      display: block;
      opacity: 0;
    }

    100% {
      display: block;
      opacity: 1;
    }
  }
`;

export const ArticleStyled = styled.article`
  position: relative;
  display: flex;
  flex: 1;
  overflow-y: auto;
  padding: 20px 0px;
  background-color: #ffffff;
  box-sizing: border-box !important;
  @media (min-width: 1024px) {
    padding: 20px 0px;
  }
`;

export const NoProductsWrapper = styled.div`
  background-color: #ffffff;
  padding: 10px 20px;
`;

export const FilterButtonStyled = styled.button<FilterButtonStyledProps>`
  display: ${({ hideButton }) => (hideButton ? "none" : "block")} !important;
  position: absolute !important;
  right: 0 !important;
  left: 0 !important;
  bottom: 64px !important;
  margin-left: auto !important;
  margin-right: auto !important;
  height: 48px !important;
  min-width: 145px !important;
  width: fit-content !important;
  padding: 0 20px !important;
  border-radius: 12px !important;
  color: #ffffff !important;
  background-color: #082f49 !important;
  cursor: pointer !important;

  @media (min-width: 1024px) {
    display: none !important;
  }
`;

export const DivStyled = styled.div<StyledProps>`
  * {
    font-family: ${({ fontFamily }) => fontFamily || "'Almarena', sans-serif"};
    font-size: 14px;
    font-weight: 500;
    box-sizing: border-box;
  }

  input,
  button {
    border: none;
  }

  /* clears the ‘X’ from Internet Explorer */
  input[type="search"]::-ms-clear {
    display: none;
    width: 0;
    height: 0;
  }
  input[type="search"]::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
  }
  /* clears the ‘X’ from Chrome */
  input[type="search"]::-webkit-search-decoration,
  input[type="search"]::-webkit-search-cancel-button,
  input[type="search"]::-webkit-search-results-button,
  input[type="search"]::-webkit-search-results-decoration {
    display: none;
  }

  .checkbox-wrapper {
    --light-blue: #4f87ff;
    --grey: #838383;
    --blue-opacity: #4f87ff66;
  }

  /* CORE STYLES */
  .checkbox-wrapper input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 18px;
    height: 18px;
    border: 0.1em solid var(--grey);
    margin-bottom: -0.125em;
  }
  .checkbox-wrapper input[type="checkbox"] {
    border-radius: 0.25em;
  }
  .checkbox-wrapper input:checked {
    border-color: transparent;
    background: var(--light-blue);
    background-clip: border-box;
    box-shadow: 0 0 0 0.1em inset #fff;
    cursor: pointer;
  }
  .checkbox-wrapper input:not(:checked):hover {
    border-color: transparent;
    background: var(--blue-opacity);
    background-clip: border-box;
    box-shadow: 0 0 0 0.1em inset #fff;
    cursor: pointer;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
`;

export const LinkWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  margin-top: 20px;
`;

export const LinkForTabOpen = styled.div`
  width: fit-content;
  cursor: pointer;
`;
