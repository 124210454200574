export const getSearchQuery = (data: { [key: string]: string }) => {
  const str = [];
  for (const p in data)
    if (data.hasOwnProperty(p) && !!data[p]) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(data[p]));
    }
  return str.join("&");
};

export const setLocalStorageValue = (key: string, data: string) => {
  localStorage.setItem(key, data);
};
export const getLocalStorageValue = (key: string) => {
  const value = localStorage.getItem(key);
  return value ? value : "";
};

export const isValidImage = async (imgUrl: string | null) => {
  if (imgUrl === null) return false;

  // Check if it's a Base64 image
  const isBase64 =
    imgUrl.startsWith("data:image/") && imgUrl.includes(";base64,");

  // Check if it's a valid URL with an image extension (accounts for query strings)
  const isImageUrl = /\.(jpg|jpeg|png|gif|bmp|webp|svg)(\?.*)?$/i.test(imgUrl);

  if (isBase64 || isImageUrl) {
    // If it's Base64, we consider it valid without further checks
    if (isBase64) {
      return true;
    }

    // If it's a URL, verify the image exists
    const imgExists = await new Promise((resolve) => {
      const img = new Image();
      img.onload = () => resolve(true); // Image exists
      img.onerror = () => resolve(false); // Image does not exist
      img.src = imgUrl;
    });

    return imgExists as boolean;
  }

  // If neither, it's not a valid image
  return false;
};
