import { FC, useEffect, useRef, useState } from "react";
import { ProductsListProps } from "./types";
import EmptyStateComponent from "../EmptyStateComponent";
import { Product } from "../../common/types";
import { ListWrapper, ProductsListStyled } from "./styled";
import { useGlobalContext } from "../../context";
import CardRedesign from "../CardRedesign";
import HorizontalCard from "../HorizontalLayout";
import { ProductsListHorizontal } from "./styled";

const ProductsList: FC<ProductsListProps> = ({
  products,
  perPage,
  count,
  setCount,
  currency,
  isLoading,
}) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const loaderRef = useRef(null);
  const [productsList, setProductList] = useState<Product[]>([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 750);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 750);

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const { state, dispatch } = useGlobalContext();
  const { collapsed, selectorModalConfig } = state;

  const productCardConfig = selectorModalConfig?.product_card_config;
  const addToCart = selectorModalConfig?.add_to_cart_enabled;
  const isVertical = selectorModalConfig?.selector_layout === "vertical";

  useEffect(() => {
    if (count === perPage) {
      ref.current?.scroll({
        top: 0,
      });
    }
    setProductList(products?.slice(0, count));
  }, [products, count]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const target = entries[0];
      if (target.isIntersecting) {
        setCount((prev) => (products?.length > prev ? prev + perPage : prev));
      }
    });

    if (loaderRef.current) {
      observer.observe(loaderRef.current);
    }

    if (ref.current) {
      ref.current?.addEventListener("scroll", () => {
        if (!collapsed) {
          dispatch({ type: "setCollapsed", payload: true });
        }
      });
    }

    return () => {
      if (loaderRef.current) {
        observer.unobserve(loaderRef.current);
      }
      if (ref.current) {
        ref.current?.removeEventListener("scroll", () =>
          dispatch({ type: "setCollapsed", payload: false })
        );
      }
    };
  });

  return (
    <>
      {isVertical || isMobile ? (
        <>
          {!!productsList?.length && (
            <ProductsListStyled ref={ref}>
              {productsList?.map((item, index) => (
                <CardRedesign
                  key={item.productUrl + index}
                  product={item}
                  productCardConfig={productCardConfig}
                  addToCart={addToCart}
                  currency={currency}
                />
              ))}
              <div ref={loaderRef}></div>
            </ProductsListStyled>
          )}
          {!productsList?.length && (
            <EmptyStateComponent
              color={productCardConfig?.no_products_found_icon_color}
              isLoading={isLoading}
            />
          )}
        </>
      ) : (
        <>
          <ListWrapper>
            {!!productsList?.length && (
              <ProductsListHorizontal>
                {productsList?.map((item, index) => (
                  <HorizontalCard
                    key={item.productUrl + index}
                    product={item}
                    productCardConfig={productCardConfig}
                    addToCart={addToCart}
                    currency={currency}
                  />
                ))}
                <div ref={loaderRef}></div>
              </ProductsListHorizontal>
            )}
          </ListWrapper>
          {!productsList?.length && (
            <EmptyStateComponent
              color={productCardConfig?.no_products_found_icon_color}
              isLoading={isLoading}
            />
          )}
        </>
      )}
    </>
  );
};

export default ProductsList;
