import { useCallback, useMemo, useState } from "react";
import X2JS from "x2js";
import { fetchProductsByVin } from "../../api/showMeTheParts";
import Dropdown from "../../common/components/Dropdown";
import {
  SelectorsLocalStorage,
  SptLocalStorageVariables,
} from "../../common/constants/localStorage";
import {
  getLocalStorageValue,
  setLocalStorageValue,
} from "../../common/helpers";
import { Wrapper, SearchButtonStyled } from "./styled";
import { Input } from "../../common/components";
import { useGlobalContext } from "../../context";

const storageVin =
  getLocalStorageValue(SelectorsLocalStorage.SELECTED_VIN_VALUE_NAME) || "";

const VIN = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [vin, setVin] = useState<string>(storageVin);
  const [vinData, setVinData] = useState<any>(null);
  const [product, setProduct] = useState<{ value: string; label: string }>({
    value: "",
    label: "",
  });
  const [selectedValue, setSelectedValue] = useState<string>("");
  const x2js = new X2JS();

  const { state, dispatch } = useGlobalContext();

  const { selectorModalConfig, year, make, model, engine, engineOptions } =
    state;

  const hidePartType = selectorModalConfig?.hide_vin_part_type;

  const licenseKey = selectorModalConfig?.license_key;
  const databaseUrl = selectorModalConfig?.database_url;

  const options = useMemo(() => {
    if (!vinData?.products?.productdata.length) {
      const item = vinData?.products?.productdata;
      if (item?.id) {
        return [{ value: item?.id, label: item?.data }];
      } else {
        return [];
      }
    }
    return vinData?.products?.productdata.map((item: any) => ({
      value: item.id,
      label: item.data,
    }));
  }, [vinData]);

  const getProducts = useCallback(async () => {
    if (!licenseKey || !databaseUrl) return;
    setIsLoading(true);
    const response = await fetchProductsByVin({
      id: licenseKey,
      url: databaseUrl,
      vin: vin,
    });

    const data: any = x2js?.xml2js(response?.data);
    const vinResp = data?.ShowMeTheParts_App_Products?.app?.app_data;

    dispatch({
      type: "setYear",
      payload: {
        ...year,
        value: vinResp.year,
        label: vinResp.year,
      },
    });

    dispatch({
      type: "setMake",
      payload: {
        ...make,
        value: vinResp.makeid,
        label: vinResp.make,
      },
    });

    dispatch({
      type: "setModel",
      payload: {
        ...model,
        value: vinResp.modelid,
        label: vinResp.model,
      },
    });

    setVinData(vinResp);
    setIsLoading(false);
  }, [licenseKey, databaseUrl, vin]);

  const handleChangeVin = useCallback(
    (value: string) => {
      if (value !== vin) {
        setVin(value);
      }
    },
    [vin]
  );

  const handleSearch = () => {
    if (vinData) {
      sessionStorage.removeItem(SptLocalStorageVariables.SPT_FILTERS);
      setLocalStorageValue(SelectorsLocalStorage.SELECTED_VIN_VALUE_NAME, vin);
      setLocalStorageValue(
        SelectorsLocalStorage.SELECTED_YEAR_KEY_NAME,
        year.value
      );
      setLocalStorageValue(
        SelectorsLocalStorage.SELECTED_YEAR_VALUE_NAME,
        year.label
      );
      setLocalStorageValue(
        SelectorsLocalStorage.SELECTED_MAKE_KEY_NAME,
        make.value
      );
      setLocalStorageValue(
        SelectorsLocalStorage.SELECTED_MAKE_VALUE_NAME,
        make.label
      );
      setLocalStorageValue(
        SelectorsLocalStorage.SELECTED_MODEL_KEY_NAME,
        model.value
      );
      setLocalStorageValue(
        SelectorsLocalStorage.SELECTED_MODEL_VALUE_NAME,
        model.label
      );
      setLocalStorageValue(
        SelectorsLocalStorage.SELECTED_PARTTYPE_KEY_NAME,
        ""
      );
      setLocalStorageValue(
        SelectorsLocalStorage.SELECTED_PARTTYPE_VALUE_NAME,
        ""
      );
      setLocalStorageValue(
        SelectorsLocalStorage.SELECTED_PRODUCT_KEY_NAME,
        ""
      );
      setLocalStorageValue(
        SelectorsLocalStorage.SELECTED_PRODUCT_VALUE_NAME,
        ""
      );
      setLocalStorageValue(SelectorsLocalStorage.SELECTED_ENGINE_KEY_NAME, "");
      setLocalStorageValue(
        SelectorsLocalStorage.SELECTED_ENGINE_VALUE_NAME,
        ""
      );
      localStorage.setItem(
        SptLocalStorageVariables.SPT_SELECTORS,
        JSON.stringify({
          year: year.value,
          make: make.value,
          model: model.value,
          product: "",
          engine: "",
        })
      );
      dispatch({
        type: "setSearchTerm",
        payload: {
          year: vinData.yearid,
          make: vinData.makeid,
          model: vinData.modelid,
          product: product.value,
          engine: vinData.engineid,
        },
      });

      dispatch({
        type: "setYear",
        payload: { ...year, value: vinData.yearid, label: vinData.year },
      });
      dispatch({
        type: "setMake",
        payload: { ...make, value: vinData.makeid, label: vinData.make },
      });
      dispatch({
        type: "setModel",
        payload: { ...model, value: vinData.modelid, label: vinData.model },
      });
      dispatch({
        type: "setProduct",
        payload: { ...product, isLoading: false, isError: false },
      });
      if (Array.isArray(engineOptions)) {
        const currentEngine = engineOptions.filter(
          (e) => e.id === vinData.engineid
        )[0];
        dispatch({
          type: "setEngine",
          payload: {
            ...engine,
            value: vinData.engineid,
            label: currentEngine.data,
          },
        });
      } else {
        dispatch({
          type: "setEngine",
          payload: {
            ...engine,
            value: vinData.engineid,
            label: engineOptions.data,
          },
        });
      }
    }
  };

  const handleImmediateSearch = async () => {
    if (!licenseKey || !databaseUrl) return;
    setIsLoading(true);
    const response = await fetchProductsByVin({
      id: licenseKey,
      url: databaseUrl,
      vin: vin,
    });
    setIsLoading(false);

    const data: any = x2js?.xml2js(response?.data);
    const vinResp = data?.ShowMeTheParts_App_Products?.app?.app_data;

    dispatch({
      type: "setYear",
      payload: {
        ...year,
        value: vinResp.year,
        label: vinResp.year,
      },
    });

    dispatch({
      type: "setMake",
      payload: {
        ...make,
        value: vinResp.makeid,
        label: vinResp.make,
      },
    });

    dispatch({
      type: "setModel",
      payload: {
        ...model,
        value: vinResp.modelid,
        label: vinResp.model,
      },
    });

    setVinData(vinResp);
    if (vinResp) {
      sessionStorage.removeItem(SptLocalStorageVariables.SPT_FILTERS);
      setLocalStorageValue(SelectorsLocalStorage.SELECTED_VIN_VALUE_NAME, vin);
      setLocalStorageValue(
        SelectorsLocalStorage.SELECTED_YEAR_KEY_NAME,
        vinResp.year
      );
      setLocalStorageValue(
        SelectorsLocalStorage.SELECTED_YEAR_VALUE_NAME,
        vinResp.yearid
      );
      setLocalStorageValue(
        SelectorsLocalStorage.SELECTED_MAKE_KEY_NAME,
        vinResp.makeid
      );
      setLocalStorageValue(
        SelectorsLocalStorage.SELECTED_MAKE_VALUE_NAME,
        vinResp.make
      );
      setLocalStorageValue(
        SelectorsLocalStorage.SELECTED_MODEL_KEY_NAME,
        vinResp.modelid
      );
      setLocalStorageValue(
        SelectorsLocalStorage.SELECTED_MODEL_VALUE_NAME,
        vinResp.model
      );
      setLocalStorageValue(
        SelectorsLocalStorage.SELECTED_PARTTYPE_KEY_NAME,
        ""
      );
      setLocalStorageValue(
        SelectorsLocalStorage.SELECTED_PARTTYPE_VALUE_NAME,
        ""
      );
      setLocalStorageValue(
        SelectorsLocalStorage.SELECTED_PRODUCT_KEY_NAME,
        ""
      );
      setLocalStorageValue(
        SelectorsLocalStorage.SELECTED_PRODUCT_VALUE_NAME,
        ""
      );
      setLocalStorageValue(SelectorsLocalStorage.SELECTED_ENGINE_KEY_NAME, "");
      setLocalStorageValue(
        SelectorsLocalStorage.SELECTED_ENGINE_VALUE_NAME,
        ""
      );
      localStorage.setItem(
        SptLocalStorageVariables.SPT_SELECTORS,
        JSON.stringify({
          year: vinResp.value,
          make: vinResp.value,
          model: vinResp.value,
          product: "",
          engine: "",
        })
      );
      dispatch({
        type: "setSearchTerm",
        payload: {
          year: vinResp.yearid,
          make: vinResp.makeid,
          model: vinResp.modelid,
          product: "",
          engine: vinResp.engineid,
        },
      });

      dispatch({
        type: "setYear",
        payload: { ...year, value: vinResp.yearid, label: vinResp.year },
      });
      dispatch({
        type: "setMake",
        payload: { ...make, value: vinResp.makeid, label: vinResp.make },
      });
      dispatch({
        type: "setModel",
        payload: { ...model, value: vinResp.modelid, label: vinResp.model },
      });
      dispatch({
        type: "setProduct",
        payload: { ...product, isLoading: false, isError: false },
      });
      if (Array.isArray(engineOptions)) {
        const currentEngine = engineOptions.filter(
          (e) => e.id === vinResp.engineid
        )[0];
        dispatch({
          type: "setEngine",
          payload: {
            ...engine,
            value: vinResp.engineid,
            label: currentEngine.data,
          },
        });
      } else {
        dispatch({
          type: "setEngine",
          payload: {
            ...engine,
            value: vinResp.engineid,
            label: engineOptions.data,
          },
        });
      }
    }
  };

  const vinLabel = [vinData?.year, vinData?.make, vinData?.model]
    .filter((item) => !!item)
    .join(" ");

  return (
    <Wrapper>
      <div>
        <Input
          id="VIN"
          label={`Vehicle: ${vinLabel}`}
          value={vin}
          onChange={(e) => {
            handleChangeVin(e.target.value);
          }}
          placeholder="VIN"
          maxLength={17}
        />
        <SearchButtonStyled
          className="search-bar-search-button-class"
          type="button"
          onClick={hidePartType ? handleImmediateSearch : getProducts}
          disabled={vin?.length !== 17 || isLoading}
        >
          {hidePartType ? "Search" : "Find Products"}
        </SearchButtonStyled>
      </div>
      {!hidePartType && (
        <div>
          <Dropdown
            value={selectedValue}
            onChange={({ value, label }) => {
              setProduct({ value, label });
              setSelectedValue(label);
            }}
            optionsList={options}
            placeholder={isLoading ? "Loading..." : "Select Product"}
            searchPlaceholder="Search..."
            disabled={!options.length}
          />
          <SearchButtonStyled
            type="button"
            className="search-bar-search-button-class"
            onClick={handleSearch}
            disabled={!product}
          >
            Search
          </SearchButtonStyled>
        </div>
      )}
    </Wrapper>
  );
};

export default VIN;
