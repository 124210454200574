import { FC, useCallback } from "react";
import Dropdown from "../../common/components/Dropdown";
import {
  SelectorsLocalStorage,
  SptLocalStorageVariables,
} from "../../common/constants/localStorage";
import { setLocalStorageValue } from "../../common/helpers";
import { YearMakeModelTabProps } from "./types";
import {
  Wrapper,
  DropdownSectionStyled,
  SearchButtonStyled,
  ClearButtonStyled,
} from "./styled";
import { useGlobalContext } from "../../context";

const defaultState = {
  value: "",
  label: "",
  isLoading: false,
  isError: false,
};

const YearMakeModelTab: FC<YearMakeModelTabProps> = ({ openApp }) => {
  const { state, dispatch } = useGlobalContext();
  const {
    year,
    make,
    model,
    product,
    productCategory,
    engine,
    yearOptions,
    makeOptions,
    modelOptions,
    productOptions,
    productCategoriesOptions,
    engineOptions,
    apiError,
    selectorModalConfig,
  } = state;
  const productEnabled = selectorModalConfig?.product_enabled;
  const partTypeEnabled = selectorModalConfig?.part_type_enabled;
  const engineEnabled = selectorModalConfig?.engine_enabled;

  const handleChangeYear = (data: { value: string; label: string }) => {
    if (data.label !== year.label) {
      dispatch({
        type: "setYear",
        payload: {
          ...year,
          value: data.value,
          label: data.label || data.value,
        },
      });
      // Reset related states
      dispatch({ type: "setMake", payload: defaultState });
      dispatch({ type: "setModel", payload: defaultState });
      dispatch({ type: "setProduct", payload: defaultState });
      dispatch({ type: "setEngine", payload: defaultState });

      // Clear options
      dispatch({ type: "setMakeOptions", payload: [] });
      dispatch({ type: "setModelOptions", payload: [] });
      dispatch({ type: "setProductOptions", payload: [] });
      dispatch({ type: "setEngineOptions", payload: [] });
    }
  };

  const handleChangeMake = useCallback(
    (data: { value: string; label: string }) => {
      dispatch({
        type: "setMake",
        payload: {
          ...make,
          value: data.value,
          label: data.label || data.value,
        },
      });

      if (data.label !== make.label) {
        dispatch({ type: "setModel", payload: defaultState });
        dispatch({ type: "setProduct", payload: defaultState });
        dispatch({ type: "setEngine", payload: defaultState });

        // Clear options
        dispatch({ type: "setModelOptions", payload: [] });
        dispatch({ type: "setProductOptions", payload: [] });
        dispatch({ type: "setEngineOptions", payload: [] });
      }
    },
    [make]
  );

  const handleChangeModel = useCallback(
    (data: { value: string; label: string }) => {
      if (data.label !== model.label) {
        dispatch({
          type: "setModel",
          payload: {
            ...model,
            value: data.value,
            label: data.label || data.value,
          },
        });

        dispatch({ type: "setProduct", payload: defaultState });
        dispatch({ type: "setEngine", payload: defaultState });

        dispatch({ type: "setProductOptions", payload: [] });
        dispatch({ type: "setEngineOptions", payload: [] });
      }
    },
    [model]
  );

  const handleChangeProduct = useCallback(
    (data: { value: string; label: string }) => {
      if (data.label === "ALL") {
        dispatch({
          type: "setProduct",
          payload: { ...defaultState, label: "ALL" },
        });
      } else if (data.label !== product.label) {
        dispatch({
          type: "setProduct",
          payload:
            product.label !== data.label
              ? {
                  ...product,
                  value: data.value,
                  label: data.label || data.value,
                }
              : product,
        });

        dispatch({ type: "setEngine", payload: defaultState });

        dispatch({ type: "setEngineOptions", payload: [] });
      }
    },
    [product]
  );

  const handleChangeProductCategory = useCallback(
    (data: { value: string; label: string }) => {
      if (data.label === "ALL") {
        dispatch({
          type: "setProductCategory",
          payload: { ...defaultState, label: "ALL" },
        });
      } else if (data.label !== productCategory.label) {
        dispatch({
          type: "setProductCategory",
          payload:
            productCategory.label !== data.label
              ? {
                  ...productCategory,
                  value: data.value,
                  label: data.label || data.value,
                }
              : productCategory,
        });

        dispatch({ type: "setEngine", payload: defaultState });

        dispatch({ type: "setEngineOptions", payload: [] });
      }
    },
    [product]
  );

  const handleChangeEngine = useCallback(
    (data: { value: string; label: string }) => {
      if (data.label !== engine.label) {
        dispatch({
          type: "setEngine",
          payload:
            engine.label !== data.label
              ? {
                  ...engine,
                  value: data.value,
                  label: data.label || data.value,
                }
              : engine,
        });
      }
    },
    [engine]
  );

  const handleSearch = () => {
    if (openApp) {
      let mainElement: HTMLElement | null =
        document.getElementById("spt-react-app");
      if (!!mainElement) {
        mainElement!.style.display = "block";
      }
    }

    dispatch({ type: "setCollapsed", payload: true });
    dispatch({
      type: "setSearchTerm",
      payload: {
        year: year.value,
        make: make.value,
        model: model.value,
        product: productEnabled
          ? productCategory.value
          : partTypeEnabled
          ? product.value
          : "",
        engine: engineEnabled ? engine.value : "",
      },
    });

    setLocalStorageValue(
      SelectorsLocalStorage.SELECTED_YEAR_KEY_NAME,
      year.value
    );
    setLocalStorageValue(
      SelectorsLocalStorage.SELECTED_YEAR_VALUE_NAME,
      year.label
    );
    setLocalStorageValue(
      SelectorsLocalStorage.SELECTED_MAKE_KEY_NAME,
      make.value
    );
    setLocalStorageValue(
      SelectorsLocalStorage.SELECTED_MAKE_VALUE_NAME,
      make.label
    );
    setLocalStorageValue(
      SelectorsLocalStorage.SELECTED_MODEL_KEY_NAME,
      model.value
    );
    setLocalStorageValue(
      SelectorsLocalStorage.SELECTED_MODEL_VALUE_NAME,
      model.label
    );
    setLocalStorageValue(
      SelectorsLocalStorage.SELECTED_PARTTYPE_KEY_NAME,
      product.value
    );
    setLocalStorageValue(
      SelectorsLocalStorage.SELECTED_PARTTYPE_VALUE_NAME,
      product.label
    );
    setLocalStorageValue(
      SelectorsLocalStorage.SELECTED_PRODUCT_KEY_NAME,
      productCategory.value
    );
    setLocalStorageValue(
      SelectorsLocalStorage.SELECTED_PRODUCT_VALUE_NAME,
      productCategory.label
    );
    setLocalStorageValue(
      SelectorsLocalStorage.SELECTED_ENGINE_KEY_NAME,
      engine.value
    );
    setLocalStorageValue(
      SelectorsLocalStorage.SELECTED_ENGINE_VALUE_NAME,
      engine.label
    );
    localStorage.setItem(
      SptLocalStorageVariables.SPT_SELECTORS,
      JSON.stringify({
        year: year.value,
        make: make.value,
        model: model.value,
        product: product.value,
        engine: engine.value,
      })
    );
    sessionStorage.removeItem(SptLocalStorageVariables.SPT_FILTERS);
  };

  const handleClear = () => {
    dispatch({ type: "setYear", payload: defaultState });
    dispatch({ type: "setMake", payload: defaultState });
    dispatch({ type: "setModel", payload: defaultState });
    dispatch({ type: "setProduct", payload: defaultState });
    dispatch({ type: "setEngine", payload: defaultState });

    dispatch({ type: "setMakeOptions", payload: [] });
    dispatch({ type: "setModelOptions", payload: [] });
    dispatch({ type: "setProductOptions", payload: [] });
    dispatch({ type: "setEngineOptions", payload: [] });

    dispatch({ type: "setSearchTerm", payload: {} });
    dispatch({ type: "setData", payload: null });

    const keysToRemove = [
      SelectorsLocalStorage.SELECTED_YEAR_KEY_NAME,
      SelectorsLocalStorage.SELECTED_YEAR_VALUE_NAME,
      SelectorsLocalStorage.SELECTED_MAKE_KEY_NAME,
      SelectorsLocalStorage.SELECTED_MAKE_VALUE_NAME,
      SelectorsLocalStorage.SELECTED_MODEL_KEY_NAME,
      SelectorsLocalStorage.SELECTED_MODEL_VALUE_NAME,
      SelectorsLocalStorage.SELECTED_PARTTYPE_KEY_NAME,
      SelectorsLocalStorage.SELECTED_PARTTYPE_VALUE_NAME,
      SelectorsLocalStorage.SELECTED_PRODUCT_KEY_NAME,
      SelectorsLocalStorage.SELECTED_PRODUCT_VALUE_NAME,
      SelectorsLocalStorage.SELECTED_ENGINE_KEY_NAME,
      SelectorsLocalStorage.SELECTED_ENGINE_VALUE_NAME,
    ];

    keysToRemove.forEach((k) => localStorage.removeItem(k));
  };

  const getGridTemplateColumn = () => {
    if (!partTypeEnabled && !engineEnabled && !productEnabled)
      return "minmax(25%, max-content) minmax(40%, max-content) minmax(30%, max-content)";
    if ((!partTypeEnabled && !productEnabled) || !engineEnabled)
      return "minmax(20%, max-content) minmax(30%, max-content) minmax(20%, max-content) minmax(25%, max-content)";
    return "minmax(110px, max-content) minmax(180px, max-content) minmax(180px, max-content) minmax(110px, 300px) minmax(110px, 1fr)";
  };

  return (
    <Wrapper>
      {apiError ? (
        <div className="search-bar-active-tab-class">
          Currently unable to process part lookups. Please try again later.
        </div>
      ) : (
        <>
          <DropdownSectionStyled gridStyle={getGridTemplateColumn()}>
            <Dropdown
              value={year.label}
              options={yearOptions}
              onChange={handleChangeYear}
              placeholder={year.isLoading ? "Loading..." : "Year"}
              searchPlaceholder="Search..."
            />
            <Dropdown
              value={make.label}
              options={makeOptions}
              onChange={handleChangeMake}
              placeholder={make.isLoading ? "Loading..." : "Make"}
              searchPlaceholder="Search..."
            />
            <Dropdown
              value={model.label}
              options={modelOptions}
              onChange={handleChangeModel}
              placeholder={model.isLoading ? "Loading..." : "Model"}
              searchPlaceholder="Search..."
            />
            {partTypeEnabled && !productEnabled && (
              <Dropdown
                value={product.label}
                options={productOptions}
                onChange={handleChangeProduct}
                placeholder={product.isLoading ? "Loading..." : "Part type"}
                searchPlaceholder="Search..."
              />
            )}
            {productEnabled && (
              <Dropdown
                value={productCategory.label}
                options={productCategoriesOptions}
                onChange={handleChangeProductCategory}
                placeholder={
                  productCategory.isLoading ? "Loading..." : "Product category"
                }
                searchPlaceholder="Search..."
              />
            )}
            {engineEnabled && (
              <Dropdown
                value={engine.label}
                options={engineOptions}
                onChange={handleChangeEngine}
                placeholder={engine.isLoading ? "Loading..." : "Engine"}
                searchPlaceholder="Search..."
              />
            )}
          </DropdownSectionStyled>
          <SearchButtonStyled
            type="button"
            className="search-bar-search-button-class"
            onClick={handleSearch}
            disabled={!model.value}
          >
            Search
          </SearchButtonStyled>
          <ClearButtonStyled
            type="button"
            className="search-bar-clear-button"
            onClick={handleClear}
            disabled={!year.value}
          >
            Clear
          </ClearButtonStyled>
        </>
      )}
    </Wrapper>
  );
};

export default YearMakeModelTab;
